import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { Row } from "../../components/Row";
import { QuizMetadataForm } from "../../components/SKComponents/QuizMetadataForm";
import { useKeyedParams } from "../../hooks/useParams";
import { useDataProvider } from "../../hooks/dataProvider";
import { useRequestHandler } from "../../hooks/requestHandler";
import * as colors from "../../util/colors";
import * as utils from "../../util/skUtils";
import * as skTypes from "../../types/sk";
import {
  createQuiz,
  getSeasonInfo,
  setSelectedSeason,
} from "../../state/actions/skActions";
import { v4 as uuid } from "uuid";
import "./SK.scss";
import { Link } from "react-router-dom";

interface SKMenuProps {
  seasonId: string;
  eventId: string;
}

export function NewQuiz() {
  const navigate = useNavigate();
  const defaultTeams: Readonly<string[]> = ["", "", ""];
  const { seasonId, eventId } = useKeyedParams<SKMenuProps>();
  const { selectedQuiz } = useSelector((state: RootStore) => state.sk);
  const { user } = useSelector((state: RootStore) => state.authentication);

  const [newQuizData, setNewQuizData] = useState<skTypes.QuizSummary>({
    quizId: uuid(),
    seasonId,
    eventId,
    quizName: "",
    site: 1,
    round: 1,
    teams: defaultTeams.map(() => ({
      teamId: "",
      teamName: "Empty",
      quizzers: [],
      active: false,
    })),
    isPractice: false,
    createdTimeStamp: "",
    lastUpdated: "",
  });
  const updateQuizData = (key: string, value: any) => {
    const newData: skTypes.QuizSummary = { ...newQuizData };
    // @ts-ignore
    newData[key] = value;
    setNewQuizData(newData);
  };

  const handler = useRequestHandler({
    onSuccess: () => {
      if (selectedQuiz?.quizId === newQuizData.quizId)
        navigate(`/sk/${seasonId}/${eventId}/${selectedQuiz.quizId}`);
    },
    loading: (state) => state.sk.skRequestLoading,
    assumeCachedInfo: true,
  });
  const provider = useDataProvider({
    id: seasonId,
    getId: (season) => season.seasonId,
    dispatchAction: setSelectedSeason,
    getData: getSeasonInfo,
    selector: (state) => state.sk.selectedSeason,
    cacheDetails: {
      prefix: "season",
      get: utils.getLocalSeason,
      save: utils.addSeasonToLocalStore,
    },
    render: (season) => {
      const event = season.events.find((ev) => ev.eventId === eventId);
      if (!event) {
        return <Navigate to={`/sk/${seasonId}/events`} />;
      }

      return (
        <div className="page" style={{ minWidth: 700 }}>
          {handler.snackbar}
          <Row>
            <Link
              className="back-button"
              style={{ marginRight: 15 }}
              to={`/sk/${seasonId}/${eventId}`}
            >
              Back
            </Link>
            <h1>New Quiz</h1>
          </Row>

          <div
            style={{
              border: `4px solid ${colors.bqaDarkGrey}`,
              padding: 15,
              marginTop: 20,
              borderRadius: 10,
            }}
          >
            <QuizMetadataForm
              data={newQuizData}
              onUpdateDetails={updateQuizData}
              quizmasterList={season.quizmasters}
            />
            <div style={{ display: "flex" }}>
              {newQuizData.teams.map((team: skTypes.Team, index: number) => (
                <div
                  key={`team-${index}`}
                  style={{ width: 220, marginRight: 15 }}
                >
                  <div style={{ marginBottom: 5 }}>
                    <label htmlFor={`quiz-team-${index}`}>
                      {`${utils.sides[index]} Team:`}
                    </label>
                  </div>
                  <select
                    id={`quiz-team-${index}`}
                    onChange={(e) => {
                      const newTeams = [...newQuizData.teams];
                      newTeams[index].teamId = e.target.value;
                      updateQuizData("teams", newTeams);
                    }}
                    value={team.teamId}
                    style={{ marginBottom: 10, marginRight: 10, width: "100%" }}
                  >
                    <option value="">Empty</option>
                    {season.teams.map((team: skTypes.Team) => (
                      <option key={team.teamId} value={team.teamId}>
                        {team.teamName}
                      </option>
                    ))}
                  </select>
                  {team.teamId !== "" &&
                    season.teams
                      .find(
                        (listTeam: skTypes.Team) =>
                          team.teamId === listTeam.teamId
                      )
                      ?.quizzers.filter((quizzer) => quizzer.active)
                      .map((quizzer: skTypes.Quizzer) => (
                        <div key={quizzer.quizzerId}>{quizzer.quizzerName}</div>
                      ))}
                </div>
              ))}
            </div>

            <div style={{ marginTop: 40 }}>
              <button
                className="clickable"
                onClick={() => {
                  // Fill teams, and check for duplicate teams
                  const teamsUsed: Set<string> = new Set<string>();
                  const quizTeams: skTypes.Team[] = [];
                  newQuizData.teams.forEach((team: skTypes.Team) => {
                    if (team.teamId && !teamsUsed.has(team.teamId)) {
                      teamsUsed.add(team.teamId);
                      const actualTeam: skTypes.Team | undefined =
                        season.teams.find(
                          (seasonTeam: skTypes.Team) =>
                            seasonTeam.teamId === team.teamId
                        );
                      if (actualTeam && actualTeam.active)
                        quizTeams.push({
                          ...actualTeam,
                          quizzers: actualTeam.quizzers.filter(
                            (quizzer: skTypes.Quizzer) => quizzer.active
                          ),
                          presetLineups: undefined,
                        });
                    }
                  });
                  const newQuiz = {
                    ...newQuizData,
                    teams: quizTeams,
                    isPractice: false,
                    moments: [],
                  };
                  handler.runRequest(
                    createQuiz(newQuiz, user !== undefined, season),
                    "Creating the quiz...",
                    "Quiz created!"
                  );
                }}
              >
                Start Quiz!
              </button>
            </div>
          </div>
        </div>
      );
    },
  });

  return provider.getPage();
}
