import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../../state/store";
import { DataTable } from "../../../components/DataTable/DataTable";
import { Row } from "../../../components/Row";
import { StatsSettings } from "../../../components/SKComponents/StatsSettings";
import { Check } from "../../../components/Check/Check";
import { useKeyedParams } from "../../../hooks/useParams";
import { useDataProvider } from "../../../hooks/dataProvider";
import * as skTypes from "../../../types/sk";
import * as statsUtils from "../../../util/skStatsUtils";
import { getPerAmount, percentageNumber } from "../../../util/generalUtil";
import {
  getSeasonInfo,
  setSelectedSeason,
} from "../../../state/actions/skActions";
import { addSeasonToLocalStore, getLocalSeason } from "../../../util/skUtils";

interface TeamStatsProps {
  seasonId: string;
}

export function TeamStats() {
  const { seasonId } = useKeyedParams<TeamStatsProps>();
  const provider = useDataProvider({
    getData: getSeasonInfo,
    selector: (state) => state.sk.selectedSeason,
    dispatchAction: setSelectedSeason,
    id: seasonId,
    getId: (season) => season.seasonId,
    render: (season) => {
      return <TeamStatsComponent selectedSeason={season} />;
    },
    fallbackUrl: "/sk/seasons",
    cacheDetails: {
      prefix: "season",
      get: getLocalSeason,
      save: addSeasonToLocalStore,
    },
  });
  return provider.getPage();
}

export function TeamStatsComponent({
  selectedSeason,
}: {
  selectedSeason: skTypes.Season;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as {
    settings?: skTypes.StatsSettings;
    sortProperty?: string;
    sortReversed?: boolean;
    fromQuiz?: string;
  };
  const { seasonId } = selectedSeason;
  const { selectedEvent } = useSelector((state: RootStore) => state.sk);
  const { skIncludeOvertime, skAdvanced } = useSelector(
    (state: RootStore) => state.settings
  );

  const [perQuiz, setPerQuiz] = useState<boolean>(false);
  const [stats, setStats] = useState<skTypes.TeamStats[] | undefined>(
    undefined
  );
  const [books, setBooks] = useState<number[]>([]);
  const settings = useMemo<skTypes.StatsSettings>(() => {
    return (
      locationState?.settings ||
      statsUtils.getEmptyStatsSettings(skIncludeOvertime)
    );
  }, [locationState, skIncludeOvertime]);
  const questionFiltersOn = statsUtils.questionFiltersOn(settings);

  const setSettings = (newSettings: skTypes.StatsSettings): void => {
    navigate(location.pathname, {
      state: {
        ...locationState,
        settings: newSettings,
      },
      replace: true,
    });
  };
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  const goBack = (): void => {
    navigate(
      locationState?.fromQuiz
        ? locationState.fromQuiz
        : `/sk/${seasonId}/${selectedEvent || "events"}`
    );
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    const { stats: statsList, books: newBooksList } = statsUtils.getTeamStats(
      seasonId,
      settings
    );
    if (!statsList.length) {
      goBack();
    } else {
      setStats(Object.values(statsList));
      if (!books.length) setBooks(newBooksList);
    }
  }, []);

  if (settingsVisible) {
    return (
      <div className="page" style={{ minWidth: 700 }}>
        <StatsSettings
          purpose="quizzer"
          seasonId={seasonId}
          onGoBack={(newSettings: skTypes.StatsSettings) => {
            const { stats: statsObjects } = statsUtils.getTeamStats(
              seasonId,
              newSettings
            );
            setStats(Object.values(statsObjects));
            setSettings(newSettings);
            setSettingsVisible(false);
          }}
          defaultSettings={settings}
          books={books}
        />
      </div>
    );
  }
  return (
    <div className="page" style={{ minWidth: 700 }}>
      <Row style={{ marginBottom: 10, position: "relative" }}>
        <button className="back-button" onClick={goBack}>
          Go Back
        </button>
        <h1 style={{ marginLeft: 10 }}>Team Stats</h1>
        <Row style={{ position: "absolute", right: 0 }}>
          <Check
            checked={perQuiz}
            onClick={setPerQuiz}
            style={{ width: 150, marginTop: -3 }}
          >
            Show Per Quiz
          </Check>
          <button
            className="clickable"
            style={{ marginLeft: 10 }}
            onClick={() => setSettingsVisible(true)}
          >
            Settings
          </button>
        </Row>
      </Row>
      <DataTable<skTypes.TeamStats>
        data={stats || []}
        defaultSort={{
          property: "quizCount",
          direction: "desc",
        }}
        columns={[
          {
            title: "Team Name",
            property: "name",
            render: (obj) => obj.teamName,
            sortFunc: true,
          },
          {
            title: "Quizzes",
            property: "quizCount",
            render: (obj) => obj.quizzes.size,
            sortFunc: true,
          },
          {
            title: perQuiz ? "Pts/Quiz" : "Points",
            property: "points",
            visible: !questionFiltersOn,
            render: (obj) =>
              perQuiz ? getPerAmount(obj.points, obj.quizzes.size) : obj.points,
            sortFunc: (obj) =>
              perQuiz
                ? percentageNumber(obj.points, obj.quizzes.size)
                : obj.points,
          },
          {
            title: perQuiz ? "Crt/Quiz" : "Correct",
            property: "correct",
            render: (obj) =>
              perQuiz
                ? getPerAmount(obj.correct, obj.quizzes.size)
                : obj.correct,
            sortFunc: (obj) =>
              perQuiz
                ? percentageNumber(obj.correct, obj.quizzes.size)
                : obj.correct,
          },
          {
            title: perQuiz ? "Ers/Quiz" : "Errors",
            property: "errors",
            render: (obj) =>
              perQuiz ? getPerAmount(obj.errors, obj.quizzes.size) : obj.errors,
            sortFunc: (obj) =>
              perQuiz
                ? percentageNumber(obj.errors, obj.quizzes.size)
                : obj.errors,
          },
          {
            title: perQuiz ? "PtsLost/Quiz" : "Points Lost to Errors",
            property: "pointsLost",
            render: (obj) =>
              perQuiz
                ? getPerAmount(obj.pointsLostToErrors, obj.quizzes.size)
                : obj.pointsLostToErrors,
            sortFunc: (obj) =>
              perQuiz
                ? percentageNumber(obj.pointsLostToErrors, obj.quizzes.size)
                : obj.pointsLostToErrors,
          },
          {
            title: "Record",
            property: "teamRecord",
            render: (obj) => {
              let recordResult: string = "";
              obj.record.forEach(
                (item: string | number, recordIndex: number) => {
                  recordResult = `${recordResult}${
                    recordIndex > 0 ? "-" : ""
                  }${item}`;
                }
              );
              return recordResult;
            },
          },
        ]}
        onClickCell={(obj, _, property) => {
          if (!skAdvanced || property !== "name") return;
          navigate(`/sk/${seasonId}/teamstats/${obj.teamId}`, {
            state: { teamStats: obj },
          });
        }}
        getKey={(obj) => obj.teamId}
      />
    </div>
  );
}
