import { ReactNode } from "react";
import { Tab, Tabs } from "@mui/material";

interface TabProps<E extends string | number> {
  value: E;
  onChange: (value: E) => void;
  tabs: {
    title: ReactNode;
    key: E;
    visible?: boolean;
  }[];
}

export function BQATabs<E extends string | number>(props: TabProps<E>) {
  if (props.tabs.length <= 1) return null;

  const visibleTabs = props.tabs.filter((tab) => tab.visible !== false);

  let index = visibleTabs.findIndex((tab) => tab.key === props.value);

  if (index < 0) index = 0;

  return (
    <Tabs
      value={index}
      variant="scrollable"
      scrollButtons="auto"
      textColor="secondary"
      onChange={(_, value) => props.onChange(visibleTabs[value].key)}
      style={{ marginBottom: 10 }}
    >
      {visibleTabs.map((tab) => {
        return (
          <Tab
            key={tab.key}
            label={tab.title}
            sx={{ textTransform: "none", fontSize: 16 }}
          />
        );
      })}
    </Tabs>
  );
}
