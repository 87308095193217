import { useState, useMemo, CSSProperties } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { useNavigate, useLocation } from "react-router-dom";
import { Check } from "../../components/Check/Check";
import { Row } from "../../components/Row";
import { Selection } from "../../components/Inputs";
import { BQATabs } from "../../components/BQATabs/BQATabs";
import { DataTable } from "../../components/DataTable/DataTable";
import { TournamentLink } from "../../components/TournamentComponents/TournamentLink";
import { TournamentText } from "../../components/TournamentComponents/TournamentText";
import { TournamentTrivia } from "../../components/TournamentComponents/TournamentTrivia";
import { TournamentSidebar } from "../../components/TournamentComponents/TournamentSidebar";
import { EditTournamentEvent } from "./EditTournaments/EditEventForm";
import { EditTQuizForm } from "./EditTournaments/EditQuizForm";
import { useEffectAfterMount } from "../../hooks/useEffectAfterMount";
import {
  TEvent,
  Tournament,
  TQuizzerStats,
  TRoster,
  TStats,
} from "../../types/tournamentTypes";
import { bqaBlue } from "../../util/colors";
import { getDateRangeDisplay } from "../../util/dateUtils";
import { getRankString, scrollToId } from "../../util/generalUtil";
import { getTeamNameDisplay } from "../../util/tournamentUtils";
import { tournamentEditAbility } from "../../util/userUtil";
import { Grid } from "@mui/material";
import "./Tournaments.scss";

interface Props {
  page: Tournament;
}
export function TournamentDetailDisplay(props: Props) {
  const tournament = props.page;
  const eventList = tournament.events.sort((ev1, ev2) => {
    if (ev1.date === ev2.date) {
      return (ev1.order || 0) - (ev2.order || 0);
    }
    return ev1.date.localeCompare(ev2.date);
  });
  const location = useLocation();
  const navigate = useNavigate();
  const selectedTab =
    location.state?.tournamentPageTab !== undefined
      ? location.state.tournamentPageTab
      : "overview";
  const selectedTeamId = location.state?.tournamentSelectedTeam as
    | string
    | undefined;
  const setSelectedTeam = (teamId: string | undefined) => {
    navigate(location.pathname, {
      state: { ...location.state, tournamentSelectedTeam: teamId },
      replace: true,
    });
  };

  const setSelEvent = (tab: string) =>
    navigate(location.pathname, {
      state: {
        ...location.state,
        tournamentPageTab: tab,
        selectedQuiz: undefined,
      },
      replace: true,
    });

  const selectedEvent = useMemo(
    () => eventList.find((ev) => ev.eventName === selectedTab),
    [eventList, selectedTab]
  );

  // Helps out with lookups, and is neded for individual quizzes
  const allQuizzersMap = useMemo(() => {
    const result: Record<string, string> = {};
    Object.values(props.page.rosters || {}).forEach((team) => {
      Object.keys(team.quizzers).forEach((qId) => {
        result[qId] = team.quizzers[qId];
      });
    });
    return result;
  }, [props.page.rosters]);

  return (
    <div>
      <BQATabs
        value={selectedTab}
        onChange={setSelEvent}
        tabs={[
          {
            title: "Tournament Overview",
            key: "overview",
          },
          {
            title: "Final Standings",
            key: "standings",
            visible: !!tournament.stats,
          },
          ...eventList.map((ev) => ({
            title: ev.eventName,
            key: ev.eventName,
          })),
          {
            title: "Statistics",
            key: "stats",
            visible: eventList.length > 0,
          },
        ]}
      />
      {selectedEvent && (
        // Event
        <EventDisplay
          tournament={tournament}
          event={selectedEvent}
          selectedTeam={selectedTeamId}
          onSelectTeam={setSelectedTeam}
          key={selectedEvent.eventName}
          quizzersMap={allQuizzersMap}
        />
      )}
      {!selectedEvent && selectedTab === "overview" && (
        // Tournament Overview
        <TournamentOverviewDisplay
          tournament={tournament}
          selectedTeamId={selectedTeamId}
          setSelectedTeam={setSelectedTeam}
        />
      )}
      {!selectedEvent && selectedTab === "standings" && (
        // Standings
        <TournamentStandingsDisplay
          tournament={tournament}
          selectedTeam={selectedTeamId}
          onSelectTeam={setSelectedTeam}
        />
      )}
      {!selectedEvent && selectedTab === "stats" && (
        // Stats
        <TournamentStatsTab
          tournament={tournament}
          selectedTeam={selectedTeamId}
          onSelectTeam={setSelectedTeam}
          quizzersMap={allQuizzersMap}
        />
      )}
    </div>
  );
}

const TournamentOverviewDisplay = (props: {
  tournament: Tournament;
  selectedTeamId: string | undefined;
  setSelectedTeam: (team: string | undefined) => void;
}) => {
  const { tournament } = props;
  const mainWidth = 330;

  return (
    <div>
      <Grid container spacing={2}>
        <TournamentSidebar
          page={tournament}
          breakpoints={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 3 }}
        />
        <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
          {tournament.rosters && (
            <TeamListDisplay
              teams={tournament.rosters}
              width={mainWidth}
              selectedTeam={props.selectedTeamId}
              onSelectTeam={props.setSelectedTeam}
            />
          )}
        </Grid>
      </Grid>
      <TournamentTrivia page={tournament} />
    </div>
  );
};

const TeamListDisplay = (props: {
  teams: Record<string, TRoster>;
  selectedTeam: string | undefined;
  onSelectTeam: (team: string | undefined) => void;
  width: number;
}) => {
  const selectedTeam = useMemo(() => {
    if (!props.selectedTeam) return undefined;
    return props.teams[props.selectedTeam];
  }, [props.selectedTeam]);

  const [selectedMinistry, setSelectedMinistry] = useState<string>("");

  const teamIdList = useMemo(
    () =>
      Object.keys(props.teams).sort((t1, t2) =>
        props.teams[t1].teamName.localeCompare(props.teams[t2].teamName)
      ),
    [props.teams]
  );

  const ministries = useMemo(() => {
    const ministryList = new Set<string>();
    teamIdList.forEach((team) => {
      const thisMinistry = props.teams[team].ministry;
      if (thisMinistry) ministryList.add(thisMinistry);
    });
    return Array.from(ministryList).sort();
  }, [teamIdList, props.teams]);

  useEffectAfterMount(() => {
    if (selectedTeam) {
      scrollToId("selected-roster");
    }
  }, [selectedTeam]);
  return (
    <Row style={{ marginBottom: 10 }}>
      {teamIdList.length > 0 && (
        <div
          className="main-box"
          style={{ width: props.width, marginRight: 10 }}
        >
          <h3 style={{ marginTop: 0, marginBottom: 10 }}>Team Rosters</h3>
          {ministries.length > 0 && (
            <Selection
              caption="Ministry:"
              value={selectedMinistry}
              values={ministries}
              onChange={setSelectedMinistry}
              noneText="All"
              style={{ marginBottom: 10 }}
            />
          )}
          <Row>
            {teamIdList
              .filter((team) => {
                if (!selectedMinistry) return true;
                return props.teams[team].ministry === selectedMinistry;
              })
              .map((team) => (
                <div key={team} style={{ marginTop: 5, width: "50%" }}>
                  <button
                    className="link"
                    style={{ textAlign: "left" }}
                    onClick={() =>
                      props.onSelectTeam(
                        props.selectedTeam === team ? undefined : team
                      )
                    }
                  >
                    {`${props.teams[team].teamName}`}
                  </button>
                </div>
              ))}
          </Row>
        </div>
      )}
      {selectedTeam && (
        <div className="main-box" style={{ width: 300 }} id="selected-roster">
          <TournamentLink
            style={{ fontWeight: "bold", fontSize: 18 }}
            pageId={selectedTeam.teamId || selectedTeam.teamName}
            text={getTeamNameDisplay(
              selectedTeam.teamName,
              selectedTeam.ministry
            )}
          />
          {selectedTeam.coach && (
            <p>
              <span>{"Coach: "}</span>
              <TournamentLink pageId={selectedTeam.coach} />
            </p>
          )}
          {Object.keys(selectedTeam.quizzers)
            .sort((k1, k2) => {
              // DNF to the bottom
              if (selectedTeam.dnf) {
                if (
                  selectedTeam.dnf.includes(k1) &&
                  !selectedTeam.dnf.includes(k2)
                )
                  return 1;
                if (
                  !selectedTeam.dnf.includes(k1) &&
                  selectedTeam.dnf.includes(k2)
                )
                  return -1;
              }
              // Sort by quizzer name
              return selectedTeam.quizzers[k1].localeCompare(
                selectedTeam.quizzers[k2]
              );
            })
            .map((qKey) => (
              <p key={qKey}>
                <TournamentLink pageId={selectedTeam.quizzers[qKey]} />
                {selectedTeam.dnf?.includes(qKey) && (
                  <span style={{ fontSize: 12 }}>{" - Did not Finish"}</span>
                )}
              </p>
            ))}
        </div>
      )}
    </Row>
  );
};

const TournamentStandingsDisplay = (props: {
  tournament: Tournament;
  selectedTeam: string | undefined;
  onSelectTeam: (team: string | undefined) => void;
}) => {
  const { tournament } = props;

  const teamPrize = useMemo(
    () => tournament.stats?.team.some((team) => !!team.prize),
    [tournament]
  );
  const quizzerPrize = useMemo(
    () => tournament.stats?.quizzer.some((quizzer) => !!quizzer.prize),
    [tournament]
  );

  if (!tournament.stats) return null;

  return (
    <div>
      {tournament.additionalData.statsNotes && (
        <TournamentText text={tournament.additionalData.statsNotes} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={4}>
          <h3>Team Placements</h3>
          <DataTable<TStats>
            data={tournament.stats.team}
            getKey={(obj) => obj.name}
            columns={[
              {
                property: "rank",
                title: "Rank",
                render: (obj, index) => index + 1,
              },
              {
                property: "name",
                title: "Team Name",
                render: (obj) =>
                  getTeamNameDisplay(
                    obj.name,
                    tournament.rosters?.[obj.name]?.ministry
                  ),
              },
              {
                property: "points",
                title: "Points",
                render: (obj) => obj.points,
              },
              {
                property: "errors",
                title: "Errors",
                render: (obj) => obj.errors,
              },
              {
                property: "qCount",
                title: "Quiz Count",
                render: (obj) => obj.qCount,
              },
              {
                property: "prize",
                title: "Prize",
                render: (obj) => (obj.prize ? `$${obj.prize}` : ""),
                visible: teamPrize,
              },
            ]}
            onClickCell={(obj) =>
              props.onSelectTeam(
                props.selectedTeam === obj.name ? "" : obj.name
              )
            }
            rowDisplay={(obj) => ({
              selected: props.selectedTeam === obj.name,
            })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={6}>
          <h3>Individual Statistics</h3>
          <DataTable<TQuizzerStats>
            data={tournament.stats.quizzer}
            getKey={(obj) => obj.name}
            columns={[
              {
                property: "rank",
                title: "Rank",
                render: (_, index) => index + 1,
              },
              {
                property: "name",
                title: "Name",
                render: (obj) => obj.name,
              },
              {
                property: "team",
                title: "Team Name",
                render: (obj) => obj.team,
              },
              {
                property: "points",
                title: "Points",
                render: (obj) => obj.points,
              },
              {
                property: "errors",
                title: "Errors",
                render: (obj) => obj.errors,
              },
              {
                property: "qCount",
                title: "Quiz Count",
                render: (obj) => obj.qCount,
              },
              {
                property: "prize",
                title: "Prize",
                render: (obj) => (obj.prize ? `$${obj.prize}` : ""),
                visible: quizzerPrize,
              },
            ]}
            rowDisplay={(obj) => ({
              selected: props.selectedTeam === obj.team,
            })}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const EventDisplay = (props: {
  tournament: Tournament;
  event: TEvent;
  selectedTeam: string | undefined;
  onSelectTeam: (team: string | undefined) => void;
  quizzersMap: Record<number, string>;
}) => {
  const { event } = props;
  const [isEditingQuiz, setIsEditingQuiz] = useState<boolean>(false);
  const [isEditingEvent, setIsEditingEvent] = useState(false);
  const { user } = useSelector((state: RootStore) => state.authentication);
  const location = useLocation();
  const navigate = useNavigate();
  const selQuiz = location.state?.selectedQuiz || "";
  const setSelectedQuiz = (quizKey: string) =>
    navigate(location.pathname, {
      state: { ...location.state, selectedQuiz: quizKey },
    });

  const getRoundName = (index: number) => {
    return event.roundNames?.[String(index)] || `Round ${index}`;
  };

  // Find out how big the event is
  let maxRound = 0;
  let maxSite = 0;
  event.quizzes.forEach((quiz) => {
    if (quiz.round > maxRound) maxRound = quiz.round;
    if (quiz.site > maxSite) maxSite = quiz.site;
  });

  const teamNames = useMemo(() => {
    const rosters = Object.values(props.tournament.rosters || {});
    return rosters.map((team) => team.teamName).sort();
  }, [props.tournament]);

  const hasEditAccess = tournamentEditAbility(user, props.tournament.host);

  const parts = selQuiz.split("-");
  const round = Number.parseInt(parts[0]);
  const site = Number.parseInt(parts[1]);
  const thisQuiz = event.quizzes.find(
    (quiz) => quiz.round === round && quiz.site === site
  );

  if (isEditingEvent) {
    return (
      <EditTournamentEvent
        isCreate={false}
        onGoBack={() => setIsEditingEvent(false)}
        pageId={props.tournament.pageId}
        secondaryId={props.tournament.secondaryId}
        event={props.event}
      />
    );
  }
  if (isEditingQuiz) {
    return (
      <EditTQuizForm
        tournament={props.tournament}
        event={props.event}
        quiz={thisQuiz}
        purpose={thisQuiz ? "UPDATE" : "CREATE"}
        onGoBack={() => setIsEditingQuiz(false)}
        quizzerMap={props.quizzersMap}
        teamNames={teamNames}
      />
    );
  }

  if (thisQuiz) {
    let roundSite = "";
    if (maxRound > 1) roundSite = getRoundName(thisQuiz.round);
    if (maxRound > 1 && maxSite > 1) roundSite += " ";
    if (maxSite > 1) roundSite += `Site ${thisQuiz.site}`;
    return (
      <div style={{ marginTop: 20 }} className="main-box single-quiz-view">
        <Row>
          <button
            className="link"
            onClick={() => {
              setIsEditingQuiz(false);
              navigate(-1);
            }}
            style={{ paddingLeft: 0 }}
          >
            Back to Quizzes
          </button>
          {hasEditAccess && (
            <button
              className="link"
              style={{ marginLeft: 10 }}
              onClick={() => setIsEditingQuiz(true)}
            >
              Edit Quiz
            </button>
          )}
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 5 }}>
          {roundSite && (
            <h3 style={{ margin: 0, marginRight: 15 }}>{roundSite}</h3>
          )}
          {thisQuiz.qm && (
            <>
              <span style={{ display: "inline-block" }}>QM:</span>
              &nbsp;
              <TournamentLink pageId={thisQuiz.qm} />
            </>
          )}
          {thisQuiz.livestream && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={thisQuiz.livestream}
              style={{ marginLeft: 15 }}
            >
              Livestream
            </a>
          )}
        </Row>
        <Row>
          {thisQuiz.teams
            .sort((t1, t2) => t1.index - t2.index)
            .map((team, index) => {
              const rosterTeam = props.tournament.rosters?.[team.teamName];
              return (
                <div
                  key={team.teamName}
                  style={{
                    width: thisQuiz.isIndividual ? 400 : 220,
                    marginLeft: index > 0 ? 15 : 0,
                  }}
                >
                  {!thisQuiz.isIndividual && (
                    <>
                      <h4 className="quiz-team">{`${team.p}. ${team.teamName}${
                        rosterTeam?.ministry ? ` - ${rosterTeam.ministry}` : ""
                      }`}</h4>
                      <p>
                        <span style={{ fontWeight: "bold", fontSize: 18 }}>
                          {team.s}
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span>{team.e}</span>
                      </p>
                    </>
                  )}
                  {team.quizzers.map((quizzer, qIndex) => {
                    const qPoints = quizzer.p * 20;
                    const isQuizoutPS = qPoints === 100 && quizzer.e === 0;
                    return (
                      <Row style={{ marginBottom: 2 }} key={quizzer.qId}>
                        {thisQuiz.isIndividual && (
                          <>
                            <div style={{ width: "10%" }}>{`${
                              qIndex + 1
                            }.`}</div>
                            <div style={{ width: "60%" }}>
                              {`${
                                rosterTeam?.quizzers[quizzer.qId] ||
                                props.quizzersMap[quizzer.qId] ||
                                `Quizzer ${qIndex + 1}`
                              }`}
                            </div>
                            <div style={{ width: "10%" }}>
                              {isQuizoutPS ? qPoints + 10 : qPoints}
                            </div>
                            <div style={{ width: "10%" }}>{quizzer.e}</div>
                            <div style={{ width: "10%", fontWeight: "bold" }}>
                              {quizzer.score}
                            </div>
                          </>
                        )}
                        {!thisQuiz.isIndividual && (
                          <>
                            <div
                              style={{ width: "75%" }}
                              className="no-wrap-text"
                            >
                              {`${
                                rosterTeam?.quizzers[quizzer.qId] ||
                                props.quizzersMap[quizzer.qId] ||
                                `Quizzer ${qIndex + 1}`
                              }`}
                            </div>
                            <div style={{ width: "15%" }}>
                              {isQuizoutPS ? qPoints + 10 : qPoints}
                            </div>
                            <div style={{ width: "10%" }}>{quizzer.e}</div>
                          </>
                        )}
                      </Row>
                    );
                  })}
                </div>
              );
            })}
        </Row>
      </div>
    );
  }

  // Sizes padding is included in height and width, so the actual size is a little smaller
  const quizHeight = 86;
  const quizWidth = 260;
  const padding = 8;
  const halfPadding = padding / 2;
  const headerSize = 40;

  const getBlackLineDisplay = () => {
    if (!event.blackLine) return null;
    const lineThickness = 2;
    const commonStyles: CSSProperties = {
      position: "absolute",
      borderRadius: lineThickness,
    };
    const elements: React.ReactElement[] = [];
    event.blackLine.forEach((site, index, arr) => {
      // Horizontal Part
      elements.push(
        <div
          key={elements.length}
          className="black-line-of-death"
          style={{
            ...commonStyles,
            top: 40 + site * quizHeight - halfPadding - lineThickness / 2,
            height: lineThickness,
            left: 40 + index * quizWidth - halfPadding,
            width: quizWidth + 2,
          }}
        />
      );

      // Vertical part, if needed between two rounds
      if (index > 0 && site !== arr[index - 1]) {
        const firstSite = site;
        const secondSite = arr[index - 1];
        elements.push(
          <div
            key={elements.length}
            className="black-line-of-death"
            style={{
              ...commonStyles,
              top:
                40 +
                Math.min(firstSite, secondSite) * quizHeight -
                halfPadding -
                lineThickness / 2,
              left: 40 + index * quizWidth - halfPadding,
              width: lineThickness,
              height: Math.abs(firstSite - secondSite) * quizHeight,
            }}
          />
        );
      }
    });
    return elements;
  };

  return (
    <div key={event.eventName}>
      <Row style={{ margin: "4px 0px" }}>
        <div style={{ marginTop: 4 }}>
          <span>{getDateRangeDisplay(event.date)}</span>
        </div>
        {teamNames.length > 0 && (
          <Selection
            style={{ marginLeft: 20 }}
            caption="Highlight Team:"
            spacing={false}
            value={props.selectedTeam}
            values={teamNames}
            onChange={props.onSelectTeam}
            noneText="None"
            placeholder="Team"
          />
        )}
        {hasEditAccess && (
          <>
            <button
              className="link"
              style={{ marginLeft: 5 }}
              onClick={() => setIsEditingQuiz(true)}
            >
              Add Quiz
            </button>
            <button
              className="link"
              style={{ marginLeft: 5 }}
              onClick={() => setIsEditingEvent(true)}
            >
              Edit Event
            </button>
          </>
        )}
      </Row>
      {event.description && <TournamentText text={event.description} />}
      <div
        style={{
          position: "relative",
          width: quizWidth * maxRound + headerSize,
          height: quizHeight * maxSite + headerSize,
          marginTop: 10,
        }}
      >
        {Array.from(Array(maxSite)).map((_, index) => (
          <div
            key={index}
            className="round-site-header"
            style={{
              top: quizHeight * index + headerSize,
              left: 0,
              width: headerSize - padding,
              height: quizHeight - padding,
            }}
          >
            {index + 1}
          </div>
        ))}
        {Array.from(Array(maxRound)).map((_, index) => (
          <div
            key={index}
            className="round-site-header"
            style={{
              top: 0,
              left: quizWidth * index + headerSize,
              width: quizWidth - padding,
              height: headerSize - padding,
            }}
          >
            {getRoundName(index + 1)}
          </div>
        ))}
        {event.quizzes.map((quiz) => (
          <button
            onClick={() => setSelectedQuiz(`${quiz.round}-${quiz.site}`)}
            key={quiz.quizId}
            className="quiz-wrapper"
            style={{
              height: quizHeight - padding,
              width: quizWidth - padding,
              top: (quiz.site - 1) * quizHeight + headerSize,
              left: (quiz.round - 1) * quizWidth + headerSize,
            }}
          >
            {quiz.isIndividual && (
              <>
                {quiz.teams[0].quizzers.slice(0, 2).map((quizzer, index) => (
                  <p key={quizzer.qId} className="team-row">
                    <span style={{ width: "10%" }}>{index + 1}</span>
                    <span style={{ width: "75%" }}>
                      {props.quizzersMap[quizzer.qId]}
                    </span>
                    <span style={{ width: "15%", fontWeight: "bold" }}>
                      {quizzer.score === undefined ? quizzer.p : quizzer.score}
                    </span>
                  </p>
                ))}
                {quiz.teams[0].quizzers.length > 2 && (
                  <p className="team-row">
                    {`(+${quiz.teams[0].quizzers.length - 2})`}
                  </p>
                )}
              </>
            )}
            {!quiz.isIndividual &&
              quiz.teams
                .sort((t1, t2) => t1.index - t2.index)
                .map((team) => (
                  <p key={team.teamName} className="team-row">
                    <span style={{ width: "13%" }}>
                      {getRankString(team.p)}
                    </span>
                    <span
                      style={{
                        width: "65%",
                        color:
                          props.selectedTeam === team.teamName
                            ? bqaBlue
                            : undefined,
                      }}
                    >
                      {`${team.seed !== undefined ? `(${team.seed}) ` : ""}${
                        team.teamName
                      }`}
                    </span>
                    <span style={{ width: "14%", fontWeight: "bold" }}>
                      {team.s}
                    </span>
                    <span style={{ width: "8%", textAlign: "right" }}>
                      {team.e}
                    </span>
                  </p>
                ))}
          </button>
        ))}
        {getBlackLineDisplay()}
      </div>
    </div>
  );
};

const TournamentStatsTab = (props: {
  tournament: Tournament;
  selectedTeam: string | undefined;
  onSelectTeam: (team: string | undefined) => void;
  quizzersMap: Record<number, string>;
}) => {
  interface TQuizzerStats {
    qId: number;
    team: string;
    points: number;
    errors: number;
    quizCount: number;
  }
  interface TTeamStats {
    team: string;
    points: number;
    errors: number;
    quizCount: number;
    record: number[];
    bracket: number;
  }

  const [perQuiz, setPerQuiz] = useState(false);
  const [includeIndividual, setIncludeIndividual] = useState(true);
  const [includeEvents, setIncludeEvents] = useState<Set<string>>(
    new Set(props.tournament.events.map((ev) => ev.eventName))
  );

  const [statsViewing, setStatsViewing] = useState<"QUIZZER" | "TEAM">(
    "QUIZZER"
  );

  const hasIndividual = useMemo(() => {
    for (let i = 0; i < props.tournament.events.length; i++) {
      for (let j = 0; j < props.tournament.events[i].quizzes.length; j++) {
        if (props.tournament.events[i].quizzes[j].isIndividual) return true;
      }
    }
    return false;
  }, [props.tournament]);
  const calculatedStats = useMemo(() => {
    const result = {
      quizzer: [] as TQuizzerStats[],
      team: [] as TTeamStats[],
    };

    const statsComare = (
      s1: TQuizzerStats | TTeamStats,
      s2: TQuizzerStats | TTeamStats
    ) => {
      const getValue = (
        stat: TQuizzerStats | TTeamStats,
        usingErrors: boolean = false
      ) => {
        let result = usingErrors ? stat.errors : stat.points;
        if (perQuiz) return result / stat.quizCount;
        return result;
      };
      let thisResult = getValue(s2) - getValue(s1);
      if (thisResult === 0) {
        return getValue(s1, true) - getValue(s2, true);
      }
      return thisResult;
    };

    if (statsViewing === "QUIZZER") {
      const quizzerResult: Record<number, TQuizzerStats> = {};
      props.tournament.events.forEach((event) => {
        if (!includeEvents.has(event.eventName)) return;
        event.quizzes.forEach((quiz) => {
          if (!includeIndividual && quiz.isIndividual) return;
          quiz.teams.forEach((team) => {
            team.quizzers.forEach((quizzer) => {
              if (!props.quizzersMap[quizzer.qId]) return;
              if (!quizzerResult[quizzer.qId]) {
                quizzerResult[quizzer.qId] = {
                  qId: quizzer.qId,
                  team: team.teamName,
                  points: 0,
                  errors: 0,
                  quizCount: 0,
                };
              }
              quizzerResult[quizzer.qId].points += quizzer.p * 20;
              if (quizzer.p === 5 && quizzer.e === 0)
                quizzerResult[quizzer.qId].points += 10;
              quizzerResult[quizzer.qId].errors += quizzer.e;
              quizzerResult[quizzer.qId].quizCount += 1;
            });
          });
        });
      });
      result.quizzer = Object.values(quizzerResult).sort(statsComare);
      if (props.selectedTeam)
        result.quizzer = result.quizzer.filter(
          (q) => q.team === props.selectedTeam
        );
    }
    if (statsViewing === "TEAM") {
      const teamResult: Record<string, TTeamStats> = {};
      props.tournament.events.forEach((event) => {
        if (!includeEvents.has(event.eventName)) return;
        event.quizzes.forEach((quiz) => {
          if (quiz.isIndividual) return;
          quiz.teams.forEach((team) => {
            if (!teamResult[team.teamName]) {
              teamResult[team.teamName] = {
                team: team.teamName,
                points: 0,
                errors: 0,
                quizCount: 0,
                record: [0, 0, 0],
                bracket: 0,
              };
            }
            teamResult[team.teamName].points += team.s;
            teamResult[team.teamName].errors += team.e;
            teamResult[team.teamName].quizCount += 1;
            if (team.p <= 3) {
              teamResult[team.teamName].record[team.p - 1] += 1;
              teamResult[team.teamName].bracket +=
                2 + (3 - team.p) * 4 + 0.01 * team.s;
            }
          });
        });
      });
      result.team = Object.values(teamResult).sort(statsComare);
    }
    return result;
  }, [
    includeEvents,
    props.tournament,
    statsViewing,
    perQuiz,
    includeIndividual,
    props.selectedTeam,
  ]);

  const teamIdList = useMemo(
    () =>
      !props.tournament.rosters
        ? []
        : Object.keys(props.tournament.rosters).sort((t1, t2) =>
            props.tournament.rosters![t1].teamName.localeCompare(
              props.tournament.rosters![t2].teamName
            )
          ),
    [props.tournament.rosters]
  );

  const roundStats = (value: number, decimalPlaces = 2) =>
    Math.round(value * Math.pow(10, decimalPlaces)) /
    Math.pow(10, decimalPlaces);

  return (
    <div>
      <p>
        Use this tool to get stats from the quizzes included in this tournament.
        Note that scores from overtime will be included. For final placements,
        see the Final Standings tab (if final standings have been published for
        team or quizzer placements).
      </p>
      <Row>
        <Check
          checked={false}
          outline={statsViewing === "QUIZZER"}
          onClick={() => setStatsViewing("QUIZZER")}
          style={{ width: 160 }}
        >
          Quizzer
        </Check>
        <Check
          checked={false}
          outline={statsViewing === "TEAM"}
          onClick={() => setStatsViewing("TEAM")}
          style={{ width: 160 }}
        >
          Team
        </Check>
      </Row>
      <h5 style={{ marginBottom: 0 }}>Include quizzes from these events:</h5>
      <Row style={{ marginBottom: 10 }}>
        {props.tournament.events.map((ev) => (
          <Check
            key={ev.eventName}
            style={{ width: 200, height: 32 }}
            checked={false}
            outline={includeEvents.has(ev.eventName)}
            onClick={() => {
              const newInclude = new Set(includeEvents);
              if (includeEvents.has(ev.eventName)) {
                newInclude.delete(ev.eventName);
              } else {
                newInclude.add(ev.eventName);
              }
              setIncludeEvents(newInclude);
            }}
          >
            {ev.eventName}
          </Check>
        ))}
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Check
          style={{ width: 170, marginRight: 20 }}
          checked={false}
          outline={perQuiz}
          onClick={() => setPerQuiz(!perQuiz)}
        >
          Show Per Quiz
        </Check>
        {hasIndividual && (
          <Check
            style={{ width: 220, marginRight: 20 }}
            checked={false}
            outline={includeIndividual}
            onClick={() => setIncludeIndividual(!includeIndividual)}
          >
            Include Individual Quizzes
          </Check>
        )}
        {statsViewing === "QUIZZER" && (
          <Selection
            caption="Filter by Team:"
            value={props.selectedTeam}
            onChange={props.onSelectTeam}
            values={teamIdList}
            spacing={false}
            noneText="All"
            style={{ marginTop: 6 }}
          />
        )}
      </Row>
      {statsViewing === "QUIZZER" && (
        <DataTable<TQuizzerStats>
          columns={[
            {
              title: "Place",
              property: "place",
              render: (_, index) => index + 1,
            },
            {
              title: "Name",
              property: "name",
              render: (obj) => props.quizzersMap[obj.qId],
            },
            {
              title: "Team",
              property: "team",
              render: (obj) => obj.team,
            },
            {
              title: perQuiz ? "Points/Q" : "Points",
              property: "points",
              render: (obj) =>
                perQuiz ? roundStats(obj.points / obj.quizCount) : obj.points,
              sortFunc: true,
            },
            {
              title: perQuiz ? "Errors/Q" : "Errors",
              property: "errors",
              render: (obj) =>
                perQuiz ? roundStats(obj.errors / obj.quizCount) : obj.errors,
              sortFunc: true,
            },
            {
              title: "Quiz Count",
              property: "quizCount",
              render: (obj) => obj.quizCount,
              sortFunc: true,
            },
          ]}
          data={calculatedStats.quizzer}
          getKey={(obj) => obj.qId}
          defaultSort={{
            property: "points",
            direction: "desc",
          }}
        />
      )}
      {statsViewing === "TEAM" && (
        <DataTable<TTeamStats>
          columns={[
            {
              title: "Place",
              property: "place",
              render: (_, index) => index + 1,
            },
            {
              title: "Team",
              property: "team",
              render: (obj) => obj.team,
            },
            {
              title: "Record",
              property: "record",
              render: (obj) =>
                `${obj.record[0]}-${obj.record[1]}-${obj.record[2]}`,
              sortFunc: (obj) => obj.record[0],
            },
            {
              title: perQuiz ? "Points/Q" : "Points",
              property: "points",
              render: (obj) =>
                perQuiz ? roundStats(obj.points / obj.quizCount) : obj.points,
              sortFunc: true,
            },
            {
              title: perQuiz ? "Errors/Q" : "Errors",
              property: "errors",
              render: (obj) =>
                perQuiz ? roundStats(obj.errors / obj.quizCount) : obj.errors,
              sortFunc: true,
            },
            {
              title: perQuiz ? "Bracket/Q" : "Bracket Points",
              property: "bracket",
              render: (obj) =>
                roundStats(
                  perQuiz ? obj.bracket / obj.quizCount : obj.bracket,
                  1
                ),
              sortFunc: true,
            },
            {
              title: "Quiz Count",
              property: "quizCount",
              render: (obj) => obj.quizCount,
              sortFunc: true,
            },
          ]}
          getKey={(obj) => obj.team}
          data={calculatedStats.team}
          defaultSort={{
            property: "bracket",
            direction: "desc",
          }}
        />
      )}
    </div>
  );
};
