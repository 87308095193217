import { useEffect, useState } from "react";
import { Navigate, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { Grid } from "@mui/material";
import { HelpButton } from "../../components/HelpButton";
import { Row } from "../../components/Row";
import { StringInput } from "../../components/Inputs";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useKeyedParams } from "../../hooks/useParams";
import { useRequestHandler } from "../../hooks/requestHandler";
import { useDataProvider } from "../../hooks/dataProvider";
import {
  createSeason,
  getQuiz,
  getSeasonInfo,
  setSelectedSeason,
  updateSeason,
} from "../../state/actions/skActions";
import * as skTypes from "../../types/sk";
import {
  addSeasonToLocalStore,
  getLocalQuiz,
  getLocalSeason,
  setLocalQuiz,
} from "../../util/skUtils";
import { SET_SK_EVENT } from "../../state/reducers/sk";
import "./SK.scss";

interface SKMenuProps {
  seasonId: string;
  eventId: string;
}

export function SKMenu() {
  const { seasonId, eventId } = useKeyedParams<SKMenuProps>();

  const provider = useDataProvider({
    id: seasonId,
    getId: (season) => season.seasonId,
    dispatchAction: setSelectedSeason,
    getData: getSeasonInfo,
    selector: (state) => state.sk.selectedSeason,
    cacheDetails: {
      prefix: "season",
      get: getLocalSeason,
      save: addSeasonToLocalStore,
    },
    render: (selectedSeason) => {
      return (
        <SKMenuPage
          selectedSeason={selectedSeason}
          eventId={eventId}
          seasonId={seasonId}
        />
      );
    },
  });
  return provider.getPage();
}

export function SKMenuPage(props: {
  selectedSeason: skTypes.Season;
  seasonId: string;
  eventId: string;
}) {
  const { selectedSeason, eventId, seasonId } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settings = useSelector((state: RootStore) => state.settings);
  const { user } = useSelector((state: RootStore) => state.authentication);

  const [editEventName, setEditEventName] = useState<string>("");
  const [editingEvent, setEditingEvent] = useState<boolean>(false);
  const [showStatsButtons, setShowStatsButtons] = useState(false);

  useEffect(() => {
    dispatch({
      type: SET_SK_EVENT,
      payload: {
        selectedEvent: eventId,
      },
    });
  }, []);
  useDocumentTitle(`${selectedSeason.seasonName} - BQA Scorekeeper`);

  useEffect(() => {
    if (props.selectedSeason.location === "LOCAL" && user)
      dispatch(createSeason(props.selectedSeason));
  }, []);

  const handler = useRequestHandler({
    onSuccess: (key) => {
      switch (key) {
        case "EVENT_NAME":
          setEditingEvent(false);
          break;
        case "EVENT_DELETE":
          navigate(`/sk/${seasonId}/events`);
      }
    },
    assumeCachedInfo: true,
  });

  const event = selectedSeason.events.find((ev) => ev.eventId === eventId);

  // Check for quizzes not pulled locally
  useEffect(() => {
    if (event && user) {
      event.quizzes.forEach((summary) => {
        const pullQuiz = async () => {
          const dbQuiz = await getQuiz(summary.quizId);
          if (dbQuiz) setLocalQuiz(dbQuiz);
        };
        const localQuiz = getLocalQuiz(summary.quizId);
        if (!localQuiz) pullQuiz();
      });
    }
  }, []);

  if (!event) return <Navigate to={`/sk/${seasonId}/events`} />;

  const quizBreakpoints = {
    xs: 6,
    sm: 4,
    md: 3,
    lg: 2,
    xl: 2,
  };
  return (
    <div className="page">
      <div style={{ marginBottom: 20, display: "flex" }}>
        <Link className="back-button" to="/" style={{ marginRight: 20 }}>
          Back to Menu
        </Link>
        <h1>{`${selectedSeason.seasonName} - ${event.eventName}`}</h1>
        {!editingEvent && (
          <div style={{ marginLeft: 20, marginTop: 6 }}>
            <button
              className="link"
              style={{ marginTop: 6 }}
              onClick={() => {
                setEditingEvent(true);
                setEditEventName(event?.eventName || "");
              }}
            >
              Edit Event Name
            </button>
          </div>
        )}
      </div>
      <div className="main-box">
        <div style={{ fontSize: 18, marginTop: -10, padding: 10 }}>
          <p className="important-information">
            {`You have touchscreen mode ${
              settings.touchscreen ? "en" : "dis"
            }abled. If this is not correct, change it in Settings in the Main Menu.`}
          </p>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: 6 }}>
              <Link
                className="link"
                style={{ paddingLeft: 0 }}
                to={`/sk/${selectedSeason.seasonId}/events`}
              >
                Back to Events
              </Link>
            </div>
            {editingEvent && (
              <StringInput
                caption="Edit Event Name:"
                style={{ marginTop: 0, marginLeft: 20 }}
                value={editEventName}
                onChange={setEditEventName}
                data-testid="sk-change-event-name"
                afterInput={
                  <button
                    className="clickable"
                    style={{
                      marginLeft: 15,
                      visibility:
                        editEventName !== event?.eventName
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={() => {
                      const eventIndex = selectedSeason?.events.findIndex(
                        (event: skTypes.Event) => event.eventId === eventId
                      );
                      if (
                        eventIndex !== undefined &&
                        eventIndex >= 0 &&
                        selectedSeason
                      ) {
                        const newSeason: skTypes.Season = {
                          ...selectedSeason,
                          events: [...(selectedSeason?.events || [])],
                        };
                        newSeason.events[eventIndex].eventName = editEventName;
                        handler.runRequest(
                          updateSeason(newSeason),
                          "Updating event name...",
                          "Event name saved!",
                          "EVENT_NAME"
                        );
                        setEditingEvent(false);
                      }
                    }}
                  >
                    Save
                  </button>
                }
              />
            )}
          </div>
        </div>
        <hr style={{ marginLeft: -10, width: "calc(100% + 20px)" }} />
        <Row style={{ padding: 10, position: "relative" }}>
          <Link
            className="clickable"
            style={{ marginRight: 20 }}
            to={`/sk/${seasonId}/teams`}
          >
            Teams
          </Link>
          {!showStatsButtons && (
            <button
              className="clickable"
              style={{ marginRight: 20 }}
              onClick={() => setShowStatsButtons(true)}
            >
              Stats
            </button>
          )}
          {event.quizzes?.length > 0 && false && (
            <Link
              className="link"
              style={{ marginRight: 20 }}
              to={`/sk/${seasonId}/${eventId}/export`}
            >
              Export
            </Link>
          )}
          {event.quizzes?.length === 0 && (
            <button
              className="link"
              style={{ marginRight: 20 }}
              onClick={() => {
                if (!selectedSeason) return;
                const newSeason: skTypes.Season = {
                  ...selectedSeason,
                };
                const index = newSeason.events.findIndex(
                  (ev) => ev.eventId === event.eventId
                );
                if (index >= 0) newSeason.events.splice(index, 1);
                handler.runRequest(
                  updateSeason(newSeason),
                  "Deleting this event...",
                  "Event deleted",
                  "EVENT_DELETE"
                );
              }}
            >
              Delete Event
            </button>
          )}
          <HelpButton
            style={{ position: "absolute", right: 0 }}
            tab="starting"
          />
        </Row>
        {showStatsButtons && (
          <Row style={{ padding: 10 }}>
            <Link
              className="clickable"
              style={{ marginRight: 20 }}
              to={`/sk/${seasonId}/quizzerstats`}
            >
              Quizzer Stats
            </Link>
            {!selectedSeason?.isPractice && (
              <Link
                className="clickable"
                style={{ marginRight: 20 }}
                to={`/sk/${seasonId}/teamstats`}
              >
                Team Stats
              </Link>
            )}
            {settings.skAdvanced && (
              <Link
                className="clickable"
                style={{ marginRight: 20 }}
                to={`/sk/${seasonId}/chapterstats`}
              >
                Chapter Stats
              </Link>
            )}
          </Row>
        )}
        <hr style={{ marginLeft: -10, width: "calc(100% + 20px)" }} />
        {selectedSeason.teams.length === 0 && (
          <p>To start a quiz, add at least one team.</p>
        )}
        {event.quizzes.length > 0 && (
          <h3 style={{ fontSize: 22, marginBottom: 20 }}>Quizzes</h3>
        )}
        <Grid container style={{ marginTop: 20 }} spacing={2}>
          {selectedSeason.teams.length > 0 && (
            <Grid item {...quizBreakpoints}>
              <Link
                className="quiz-clickable"
                to={`/sk/${seasonId}/${eventId}/${
                  selectedSeason?.isPractice ? "newpracticequiz" : "newquiz"
                }`}
                style={{ paddingTop: 20 }}
              >
                <span
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}
                >
                  New Quiz
                </span>
                <br />
                <span style={{ fontSize: 54 }}>+</span>
              </Link>
            </Grid>
          )}
          {event.quizzes
            .sort((q1, q2) => {
              if (q1.round !== q2.round) return q1.round - q2.round;
              if (q1.site !== q2.site) return q1.site - q2.site;
              if (!q1.createdTimeStamp || !q2.createdTimeStamp) return 0;
              return q1.createdTimeStamp.localeCompare(q2.createdTimeStamp);
            })
            .map((quiz: skTypes.QuizSummary) => (
              <Grid item key={quiz.quizId} {...quizBreakpoints}>
                <Link
                  className="quiz-clickable"
                  to={`/sk/${seasonId}/${eventId}/${quiz.quizId}`}
                  style={{ overflow: "hidden" }}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <div className="no-wrap-text">{quiz.quizName}</div>
                  <div
                    className="no-wrap-text"
                    style={{ marginBottom: 10 }}
                  >{`Round ${quiz.round}, Site ${quiz.site}`}</div>
                  {!quiz.isPractice ? (
                    quiz.teams.map((team: skTypes.Team) => (
                      <div className="no-wrap-text" key={team.teamId}>
                        {team.nickname || team.teamName}
                      </div>
                    ))
                  ) : (
                    <>
                      {quiz.teams[0]?.quizzers
                        .filter((q, i) => i < 3)
                        .map((q) => (
                          <div key={q.quizzerId} className="no-wrap-text">
                            {q.quizzerName}
                          </div>
                        ))}
                      {quiz.teams[0].quizzers.length > 3 && (
                        <div>{`(+${
                          quiz.teams.length * 5 +
                          quiz.teams[quiz.teams.length - 1].quizzers.length -
                          8
                        })`}</div>
                      )}
                    </>
                  )}
                </Link>
              </Grid>
            ))}
        </Grid>
      </div>
    </div>
  );
}
