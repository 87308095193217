import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../../state/store";
import { DataTable } from "../../../components/DataTable/DataTable";
import { Row } from "../../../components/Row";
import { StatsSettings } from "../../../components/SKComponents/StatsSettings";
import { Check } from "../../../components/Check/Check";
import * as skTypes from "../../../types/sk";
import * as statsUtils from "../../../util/skStatsUtils";
import { addSeasonToLocalStore, getLocalSeason } from "../../../util/skUtils";
import { getPerAmount } from "../../../util/generalUtil";
import { useDataProvider } from "../../../hooks/dataProvider";
import { useKeyedParams } from "../../../hooks/useParams";
import {
  getSeasonInfo,
  setSelectedSeason,
} from "../../../state/actions/skActions";

interface QuizzerStatsProps {
  seasonId: string;
}

export function QuizzerStats() {
  const { seasonId } = useKeyedParams<QuizzerStatsProps>();
  const provider = useDataProvider({
    getData: getSeasonInfo,
    selector: (state) => state.sk.selectedSeason,
    dispatchAction: setSelectedSeason,
    id: seasonId,
    getId: (season) => season.seasonId,
    render: (season) => {
      return <QuizzerStatsComponent selectedSeason={season} />;
    },
    fallbackUrl: "/sk/seasons",
    cacheDetails: {
      prefix: "season",
      get: getLocalSeason,
      save: addSeasonToLocalStore,
    },
  });
  return provider.getPage();
}

export function QuizzerStatsComponent({
  selectedSeason,
}: {
  selectedSeason: skTypes.Season;
}) {
  type SortProperty =
    | keyof skTypes.QuizzerStats
    | "seatTime"
    | "wordPercentage"
    | "jumpLuck"
    | "answerLuck";
  const location = useLocation();
  const locationState = location.state as {
    settings?: skTypes.StatsSettings;
    sortProperty?: SortProperty;
    sortReversed?: boolean;
    fromQuiz?: string;
  };
  const navigate = useNavigate();
  const { seasonId } = selectedSeason;
  const { selectedEvent } = useSelector((state: RootStore) => state.sk);
  const { skIncludeOvertime, skAdvanced } = useSelector(
    (state: RootStore) => state.settings
  );
  const { material } = useSelector((state: RootStore) => state.material);

  const [perQuiz, setPerQuiz] = useState<boolean>(false);
  const [stats, setStats] = useState<skTypes.QuizzerStats[]>([]);
  const [books, setBooks] = useState<number[]>([]);
  const settings = useMemo<skTypes.StatsSettings>(() => {
    return (
      locationState?.settings ||
      statsUtils.getEmptyStatsSettings(skIncludeOvertime)
    );
  }, [locationState, skIncludeOvertime]);
  const questionFiltersOn = statsUtils.questionFiltersOn(settings);

  const setSettings = (newSettings: skTypes.StatsSettings): void => {
    navigate(location.pathname, {
      state: {
        ...locationState,
        settings: newSettings,
      },
      replace: true,
    });
  };
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  const goBack = (): void => {
    navigate(
      locationState?.fromQuiz
        ? locationState.fromQuiz
        : `/sk/${seasonId}/${selectedEvent || "events"}`
    );
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const { stats: statsObjects, books: newBookList } =
      statsUtils.getQuizzerStats(material, selectedSeason, settings);
    if (!statsObjects.length) {
      goBack();
    } else {
      setStats(statsObjects);
      if (!books.length) setBooks(newBookList);
    }
  }, []);

  if (settingsVisible) {
    return (
      <div className="page" style={{ minWidth: 700 }}>
        <StatsSettings
          purpose="quizzer"
          seasonId={seasonId}
          onGoBack={(newSettings: skTypes.StatsSettings) => {
            if (!selectedSeason) return;
            const { stats: statsObjects } = statsUtils.getQuizzerStats(
              material,
              selectedSeason,
              newSettings
            );
            setStats(Object.values(statsObjects));
            setSettings(newSettings);
            setSettingsVisible(false);
          }}
          defaultSettings={settings}
          books={books}
          isPractice={selectedSeason?.isPractice}
        />
      </div>
    );
  }
  return (
    <div className="page" style={{ minWidth: 700 }}>
      <Row style={{ marginBottom: 10, position: "relative" }}>
        <button className="back-button" onClick={goBack}>
          Go Back
        </button>
        <h1 style={{ marginLeft: 10 }}>Quizzer Stats</h1>
        <Row style={{ position: "absolute", right: 0 }}>
          <Check
            checked={perQuiz}
            onClick={setPerQuiz}
            style={{ width: 150, marginTop: -3 }}
          >
            Show Per Quiz
          </Check>
          <button
            className="clickable"
            style={{ marginLeft: 10 }}
            onClick={() => setSettingsVisible(true)}
          >
            Settings
          </button>
        </Row>
      </Row>
      <DataTable<skTypes.QuizzerStats>
        data={[...stats, statsUtils.getTotalQuizzerStats(stats)]}
        keysAtBottom={["total"]}
        getKey={(obj) => obj.quizzerId}
        defaultSort={{
          property: "quizCount",
          direction: "desc",
        }}
        columns={[
          {
            title: "Name",
            property: "name",
            render: (obj) => obj.quizzerName,
            sortFunc: true,
          },
          {
            title: "Team",
            property: "teamName",
            visible: !selectedSeason?.isPractice,
            render: (obj) => obj.teamName,
            sortFunc: true,
          },
          {
            title: "Quizzes",
            property: "quizCount",
            render: (obj) => obj.quizzes.size,
            sortFunc: true,
          },
          {
            title: perQuiz ? "Pts/Quiz" : "Points",
            property: "points",
            visible: questionFiltersOn || !selectedSeason?.isPractice,
            render: (obj) =>
              perQuiz
                ? obj.quizzerId === "total"
                  ? "-"
                  : getPerAmount(obj.points, obj.quizzes.size)
                : obj.points,
            sortFunc: (obj) =>
              perQuiz ? obj.points / obj.quizzes.size : obj.points,
          },
          {
            title: perQuiz ? "Crt/Quiz" : "Correct",
            property: "correct",
            render: (obj) =>
              perQuiz
                ? obj.quizzerId === "total"
                  ? "-"
                  : getPerAmount(obj.correct, obj.quizzes.size)
                : obj.correct,
            sortFunc: (obj) =>
              perQuiz ? obj.correct / obj.quizzes.size : obj.correct,
          },
          {
            title: perQuiz ? "Ers/Quiz" : "Errors",
            property: "errors",
            render: (obj) =>
              perQuiz
                ? obj.quizzerId === "total"
                  ? "-"
                  : getPerAmount(obj.errors, obj.quizzes.size)
                : obj.errors,
            sortFunc: (obj) =>
              perQuiz ? obj.errors / obj.quizzes.size : obj.errors,
          },
          {
            title: "Seat Time",
            property: "seatTime",
            visible: !selectedSeason?.isPractice,
            render: (obj) =>
              obj.seatTimeEligible === 0
                ? "-"
                : `${Math.round(
                    (obj.questionsOnSeat * 100) / obj.seatTimeEligible
                  )}%`,
            sortFunc: (obj) =>
              obj.seatTimeEligible === 0
                ? 0
                : obj.questionsOnSeat / obj.seatTimeEligible,
          },
          {
            title: "Word %",
            property: "wordPercentage",
            visible: skAdvanced,
            render: (obj) => {
              const wordPercentage = statsUtils.getWordPercentage(
                obj.jumpsByType
              );
              return isNaN(wordPercentage) ? "-" : `${wordPercentage}%`;
            },
            sortFunc: (obj) => {
              const wordPercentage = statsUtils.getWordPercentage(
                obj.jumpsByType
              );
              return isNaN(wordPercentage) ? 0 : wordPercentage;
            },
          },
          {
            title: "Jump Luck",
            property: "jumpLuck",
            visible: skAdvanced,
            render: (obj) =>
              obj.luck.expectedFromJumps === 0
                ? "-"
                : `${Math.round(
                    (obj.luck.actual * 100) / obj.luck.expectedFromJumps
                  )}%`,
            sortFunc: (obj) =>
              obj.luck.expectedFromJumps === 0
                ? 0
                : obj.luck.actual / obj.luck.expectedFromJumps,
          },
          {
            title: "Answer Luck",
            property: "answerLuck",
            visible: skAdvanced,
            render: (obj) =>
              obj.luck.expectedFromQuestions === 0
                ? "-"
                : `${Math.round(
                    (obj.luck.choicesCorrect * 100) /
                      obj.luck.expectedFromQuestions
                  )}%`,
            sortFunc: (obj) =>
              obj.luck.expectedFromQuestions === 0
                ? 0
                : obj.luck.choicesCorrect / obj.luck.expectedFromQuestions,
          },
        ]}
        onClickCell={(obj, _, property) => {
          if (!skAdvanced || property !== "name") return;
          navigate(`/sk/${seasonId}/quizzerstats/${obj.quizzerId}`, {
            state: { quizzerStats: obj },
          });
        }}
      />
    </div>
  );
}
