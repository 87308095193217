import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { useNavigate, useLocation } from "react-router-dom";
import { DataTable } from "../../components/DataTable/DataTable";
import { Row } from "../../components/Row";
import { TournamentHeader } from "../../components/TournamentComponents/TournamentHeader";
import {
  TournamentLink,
  TournamentPlaceContent,
} from "../../components/TournamentComponents/TournamentLink";
import { TournamentFooter } from "../../components/TournamentComponents/TournamentFooter";
import { DropdownMenu } from "../../components/Dropdown/DropdownMenu";
import { BQATabs } from "../../components/BQATabs/BQATabs";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { getTournamentList } from "../../state/actions/tournamentActions";
import {
  TTierDescriptions,
  TournamentTiers,
  TTierDetails,
  TournamentSummary,
} from "../../types/tournamentTypes";
import { getDateRangeDisplay } from "../../util/dateUtils";
import { CircularProgress } from "@mui/material";
import { ReactComponent as DropdownArrow } from "../../icons/dropdown-arrow.svg";

export function TournamentHome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTier = (location.state?.selectedTier ||
    "NATIONALS") as TournamentTiers;
  const { tournamentList, isLoading, trending } = useSelector(
    (state: RootStore) => state.tournaments
  );
  const [readStarted, setReadStarted] = useState<boolean>(
    tournamentList.length !== 0
  );
  const setSelectedTier = (tier: TournamentTiers) => {
    navigate(location.pathname, {
      state: {
        ...location.state,
        selectedTier: tier,
      },
      replace: true,
    });
  };

  useDocumentTitle("BQA Tournaments");
  useEffect(() => {
    dispatch(getTournamentList());
    setReadStarted(true);
  }, []);
  useEffect(() => {
    if (readStarted && !isLoading) setReadStarted(false);
  }, [isLoading]);

  const displayData = tournamentList
    .filter((tourney) => tourney.tier === selectedTier)
    .sort((t1, t2) => t2.startDate.localeCompare(t1.startDate));

  return (
    <div className="page" style={{ minWidth: 700 }}>
      <meta
        name="description"
        content="Past BQF Tournaments, scores, team and quizzer bios, and more"
      />
      <TournamentHeader pageName="Tournaments" navigateBack="/" />
      <p style={{ marginBottom: 10 }}>
        BQA Tournaments tracks all past tournaments, going back as far as we
        have data. For live scores, see&nbsp;
        <a
          href="https://www.quizzing.live/scores"
          target="_blank"
          rel="noopener noreferrer"
        >
          quizzing.live
        </a>
        .
      </p>
      <p style={{ visibility: trending.length > 0 ? "visible" : "hidden" }}>
        <DropdownMenu
          items={trending.map((item) => ({
            title: `${item.pageId}${
              item.secondaryId ? ` ${item.secondaryId}` : ""
            }`,
            key: `${item.pageId}-${item.secondaryId}`,
            onClick: () => {
              navigate(
                `/tournaments/${item.pageId}${
                  item.secondaryId ? `/${item.secondaryId}` : ""
                }`.replace(/ /g, "_")
              );
            },
          }))}
          className="link"
          style={{ fontWeight: "bold", display: "flex" }}
          id="trending-pages"
        >
          <span>Trending Pages</span>
          <DropdownArrow height={22} width={22} style={{ marginLeft: 4 }} />
        </DropdownMenu>
      </p>
      <Row>
        <BQATabs
          value={selectedTier}
          onChange={setSelectedTier}
          tabs={(Object.keys(TTierDescriptions) as TournamentTiers[]).map(
            (tier) => ({
              key: tier,
              title: TTierDescriptions[tier],
            })
          )}
        />
      </Row>
      <p>{TTierDetails[selectedTier]}</p>
      {readStarted ? (
        <CircularProgress style={{ width: 60, margin: 20 }} />
      ) : (
        <>
          <DataTable<TournamentSummary>
            style={{ marginTop: 20, maxHeight: "unset" }}
            columns={[
              {
                title: "Tournament",
                property: "name",
                render: (obj) => (
                  <TournamentLink
                    pageId={obj.pageId}
                    secondaryId={obj.secondaryId}
                  />
                ),
              },
              {
                title: "Date",
                property: "dates",
                render: (obj) =>
                  getDateRangeDisplay(obj.startDate, obj.endDate),
                sortFunc: (obj) => obj.endDate,
                defaultSortDirection: "desc",
              },
              {
                title: "Teams",
                property: "teamCount",
                render: (obj) => obj.teamCount,
                sortFunc: true,
                defaultSortDirection: "asc",
              },
              {
                title: "Host",
                property: "host",
                render: (obj) => <TournamentLink pageId={obj.host} />,
                visible: selectedTier !== "LOCAL",
              },
              {
                title: "1st",
                property: "place1",
                render: (obj) => (
                  <TournamentPlaceContent tournament={obj} index={0} />
                ),
              },
              {
                title: "2nd",
                property: "place2",
                render: (obj) => (
                  <TournamentPlaceContent tournament={obj} index={1} />
                ),
              },
              {
                title: "3rd",
                property: "place3",
                render: (obj) => (
                  <TournamentPlaceContent tournament={obj} index={2} />
                ),
              },
            ]}
            data={displayData}
            getKey={(obj) => `${obj.pageId}-${obj.secondaryId}`}
          />
          <TournamentFooter />
        </>
      )}
    </div>
  );
}
