import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { CircularProgress } from "@mui/material";
import { HelpButton } from "../../components/HelpButton";
import { StringInput } from "../../components/Inputs";
import { Row } from "../../components/Row";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useRequestHandler } from "../../hooks/requestHandler";
import * as skTypes from "../../types/sk";
import { createSeason, getSeasonList } from "../../state/actions/skActions";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import "./SK.scss";

export function EditSeasons() {
  useDocumentTitle("Seasons - BQA Scorekeeper");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedSeason, seasons } = useSelector(
    (state: RootStore) => state.sk
  );
  const { user } = useSelector((state: RootStore) => state.authentication);

  useEffect(() => {
    dispatch(getSeasonList(user !== undefined));
  }, []);
  const requestHandler = useRequestHandler({
    onSuccess: (key) => {
      if (key === "CREATE" && selectedSeason) {
        navigate(`/sk/${selectedSeason.seasonId}/events`);
      }
    },
    assumeCachedInfo: true,
    loading: (state) => state.sk.skRequestLoading,
  });

  const [creatingNewSeason, setCreatingNewSeason] = useState<boolean>(false);
  const [newSeasonName, setNewSeasonName] = useState<string>("");
  const [isPractice, setIsPractice] = useState<boolean>(false);

  return (
    <div className="page">
      <meta
        name="description"
        content="Scorekeeper for coaches with automatic stats calculations and advanced features"
      />
      {requestHandler.snackbar}
      <Row style={{ marginBottom: 20, position: "relative" }}>
        <Link className="back-button" to="/">
          Back to Menu
        </Link>
        <h1 style={{ marginLeft: 20 }}>Scorekeeper</h1>
        <HelpButton style={{ position: "absolute", right: 0 }} />
      </Row>
      <button
        className="clickable"
        style={{
          width: !creatingNewSeason ? 190 : undefined,
        }}
        onClick={() => {
          setCreatingNewSeason(!creatingNewSeason);
          setIsPractice(false);
        }}
        key={creatingNewSeason ? "cancelseason" : "createseason"}
      >
        {creatingNewSeason ? "Cancel" : "Create New Season"}
      </button>

      {!creatingNewSeason && (
        <button
          className="link"
          style={{ marginLeft: 10, marginRight: 10 }}
          onClick={() => {
            setCreatingNewSeason(true);
            setIsPractice(true);
          }}
          key={creatingNewSeason ? "cancelpractice" : "createpractice"}
        >
          {creatingNewSeason ? "Cancel" : "Create New Practice Season"}
        </button>
      )}
      {!creatingNewSeason && (
        <Link to="/watchquiz" style={{ marginLeft: 10 }}>
          Watch Quiz
        </Link>
      )}
      {creatingNewSeason ? (
        <div>
          <h2 style={{ marginTop: 20 }}>Create a New Season</h2>
          {isPractice && (
            <p>
              You are creating a practice season. This is for practices and
              drills, where you can have individual quizzers or temporary teams
              quizzing. If you are making a traditional season with team
              quizzes, click Cancel and then Create New Season.
            </p>
          )}
          <StringInput
            caption="New Season Name:"
            value={newSeasonName}
            onChange={setNewSeasonName}
          />
          <button
            className="clickable"
            style={{ marginTop: 10 }}
            onClick={() => {
              if (!newSeasonName) return;
              const resultSeason: skTypes.Season = {
                seasonName: newSeasonName,
                seasonId: uuid(),
                location: "LOCAL",
                events: [],
                teams: [],
                isPractice,
                lastUpdated: dayjs().format(),
              };
              requestHandler.runRequest(
                createSeason(resultSeason, user !== undefined),
                "Creating your new season...",
                "Season created!",
                "CREATE"
              );
            }}
          >
            Create Season
          </button>
        </div>
      ) : (
        <>
          <div
            className="main-box"
            style={{
              overflowY: "auto",
              marginTop: 10,
              borderRadius: 8,
              padding: 20,
            }}
          >
            {!seasons ? (
              <CircularProgress />
            ) : (
              <div
                className="important-information"
                style={{ marginBottom: 20 }}
              >
                {seasons.length > 0
                  ? "Select a season below."
                  : "You don't have any seasons yet. Create one above."}
              </div>
            )}
            {seasons
              ?.filter(
                (season) =>
                  !user || !season.ownerId || user?.userId === season.ownerId
              )
              .map((season: skTypes.SeasonSummary) => (
                <div key={`season-${season.seasonId}`}>
                  <Link
                    className={`season-event-list ${
                      season.seasonId === selectedSeason?.seasonId
                        ? "season-event-list-active"
                        : ""
                    }`}
                    to={`/sk/${season.seasonId}/events`}
                  >
                    {season.seasonName}
                  </Link>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}
