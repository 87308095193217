import React, { useState, useRef, useEffect, CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../state/store";
import { Ruling } from "../components/Ruling";
import { Row } from "../components/Row";
import { DoubleClickable } from "../components/DoubleClickable";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { shuffle } from "../util/generalUtil";
import { swipeCutoff } from "../util/swiping";
import { Swiping, TextDetails } from "../types/generalTypes";
import {
  baseTimeForWord,
  breakupSyllables,
  timeForNextSyllable,
} from "../util/words";

const JUMPKEYS = "JUMPKEYS";
const READY = "READY";
const READING = "READING";
const WAITING = "WAITING";
const REACTING = "REACTING";
const ANSWERING = "ANSWERING";
const RULING = "RULING";
type QuizNowPhaseType =
  | typeof JUMPKEYS
  | typeof READY
  | typeof READING
  | typeof WAITING
  | typeof REACTING
  | typeof ANSWERING
  | typeof RULING;

type JumpOption = "JUMP" | "REF" | "ONE" | "TWO" | "FULL";
const JumpOptionDescriptions: Record<JumpOption, string> = {
  JUMP: "Jump",
  REF: "Reference",
  ONE: "One Word",
  TWO: "Two Words",
  FULL: "Full Question",
};
const statsColumnWidths: string[] = ["15%", "10%", "10%", "10%", "10%", "10%"];

interface QuestionType {
  book: number;
  chapter: number;
  verse: number;
  question: string;
}
export function QuizNow() {
  useDocumentTitle("Quiz Now - Bible Quiz Academy");
  const getFullQuestion = (q: QuestionType): string => {
    if (!q) return "";
    let question = "";
    if (settings.qnBookName) question += material[q.book].bookName + " ";
    question += `${q.chapter + 1}${q.chapter + 1 === 20 ? " verse" : ":"} ${
      q.verse + 1
    } ${q.question}`;
    return question;
  };
  const getVerse = (q: QuestionType): string => {
    if (!q) return "";
    return `${material[q.book].bookName} ${q.chapter + 1}${
      q.chapter + 1 === 20 ? " verse" : ":"
    } ${q.verse + 1} ${
      material[q.book].chapters[q.chapter][q.verse]?.verse || ""
    }`;
  };

  /**
   * Verse and question display
   * @param q the full question data to display
   * @param verseOverride viewing a different verse
   * @returns react elements for the display
   */
  const getVerseAndQuestions = (q: QuestionType, verseOverride: number) => {
    if (!q) return [];
    let resultingVerse = q.verse;
    if (verseOverride !== undefined && verseOverride >= 0)
      resultingVerse = verseOverride;
    const thisVerse = getVerse({ ...q, verse: resultingVerse });
    const theseQuestions =
      material[q.book].chapters[q.chapter][resultingVerse]?.questions;

    let questionReceived = "";
    if (q.verse === verseOverride) {
      let firstWordIndex = -1;
      for (let i = 0; i < 10; i++) {
        if (
          words.current[i].post === ": " ||
          words.current[i].normal.includes("verse")
        ) {
          firstWordIndex = i + 2;
          break;
        }
      }
      for (
        let i = firstWordIndex;
        i < firstWordIndex + wordsGiven.current;
        i++
      ) {
        questionReceived += words.current[i].normal + words.current[i].post;
      }
      for (let i = 0; i < currentSyllable.current; i++) {
        if (i === 0) questionReceived += " ";
        questionReceived +=
          words.current[firstWordIndex + wordsGiven.current].syllables[i];
      }
    }

    return (
      <>
        <span>{thisVerse}</span>
        <br />
        <br />
        {theseQuestions.map((q) => (
          <span
            key={q}
            style={{
              color:
                questionReceived && q.toLowerCase().startsWith(questionReceived)
                  ? "red"
                  : undefined,
            }}
          >
            {q}
            <br />
          </span>
        ))}
      </>
    );
  };

  const navigate = useNavigate();
  const { material } = useSelector((state: RootStore) => state.material);
  const quizzerNames = useSelector((state: RootStore) => {
    const result: string[] = [];
    state.settings.quizNowQuizzers.forEach((item: string) => {
      if (item) result.push(item);
    });
    return result;
  });
  const { qmPrecise, qmSpeed } = useSelector(
    (state: RootStore) => state.settings
  );
  const getEmptyStatsArray = (): number[] => {
    return Array(quizzerNames.length).fill(0);
  };
  const [correct, setCorrect] = useState<number[]>(getEmptyStatsArray());
  const [errors, setErrors] = useState<number[]>(getEmptyStatsArray());
  const [statsWords, setStatsWords] = useState<number[]>(getEmptyStatsArray());
  const [timeUsed, setTimeUsed] = useState<number[]>(getEmptyStatsArray());
  const [questionsAsked, setQuestionsAsked] = useState<number>(0);
  const swipeStatusDefault: Swiping = {
    sX: -1,
    sY: -1,
    eX: -1,
    eY: -1,
  };
  const [swipeStatus, setSwipeStatus] = useState<Swiping>(swipeStatusDefault);
  const settings = useSelector((state: RootStore) => state.settings);

  const [jumpOption, setJumpOption] = useState<JumpOption>("JUMP");
  const [phase, setPhase] = useState<QuizNowPhaseType>(JUMPKEYS);
  const [peeking, setPeeking] = useState<boolean>(false);
  const [jumpKeys, setJumpKeys] = useState<
    (string | { x: number; y: number })[]
  >([]);

  const [questionSet, setQuestionSet] = useState<QuestionType[]>([]);
  // Generate a question set
  useEffect(() => {
    if (!material?.length) return;
    const allQuestions: QuestionType[] = [];
    const allQuotes: QuestionType[] = [];
    settings.selectedChapters.forEach((book, bookIndex) => {
      book.forEach((chapter, chapterIndex) => {
        const materialChapter = material[bookIndex].chapters[chapterIndex];
        const chapterSettings =
          typeof chapter === "boolean"
            ? Array(materialChapter.length).fill(chapter)
            : chapter;
        if (chapter) {
          materialChapter.forEach((verse, verseIndex) => {
            if (chapterSettings[verseIndex]) {
              allQuotes.push({
                book: bookIndex,
                chapter: chapterIndex,
                verse: verseIndex,
                question: "Quote? " + verse.verse,
              });
              verse.questions.forEach((question) => {
                if (question)
                  allQuestions.push({
                    book: bookIndex,
                    chapter: chapterIndex,
                    verse: verseIndex,
                    question: question,
                  });
              });
            }
          });
        }
      });
    });
    const setSize = Math.min(allQuestions.length, 1000);
    const set = [];
    for (let i = 0; i < setSize * 0.85; i++) {
      const num = Math.floor(Math.random() * allQuestions.length);
      if (allQuestions[num]) set.push(allQuestions[num]);
      allQuestions.splice(num, 1);
    }
    for (let i = 0; i < setSize * 0.15; i++) {
      const num = Math.floor(Math.random() * allQuotes.length);
      if (allQuotes[num]) set.push(allQuotes[num]);
      allQuotes.splice(num, 1);
    }
    shuffle(set);
    setQuestionSet(set);
  }, []);

  const [screenText, setScreenText] = useState<string>("");
  const realScreenText = useRef<string>("");
  const [fullQuestion, setFullQuestion] = useState<string>("");
  const [verseViewing, setVerseViewing] = useState<number>(-1);
  const words = useRef<TextDetails[]>([]);
  const currentWord = useRef(0);
  const currentSyllable = useRef(0);
  const wordsGiven = useRef(0);
  const [guessQuoteVisible, setGuessQuoteVisible] = useState<boolean>(false);

  const readWord = () => {
    let newText = "";
    let nextTime = 0;
    const thisWord = words.current[currentWord.current];
    if (qmPrecise && jumpOption === "JUMP") {
      newText = realScreenText.current;
      if (currentSyllable.current === 0) newText += thisWord.pre;
      newText += thisWord.syllables[currentSyllable.current];
      currentSyllable.current++;
      nextTime = timeForNextSyllable(
        thisWord,
        currentSyllable.current,
        qmSpeed
      );
      if (currentSyllable.current === thisWord.syllables.length) {
        newText += thisWord.post;
        currentSyllable.current = 0;
        currentWord.current++;
      }
    } else {
      newText =
        realScreenText.current + thisWord.pre + thisWord.text + thisWord.post;
      nextTime = baseTimeForWord(qmSpeed);
      currentWord.current = currentWord.current + 1;
    }

    setScreenText(newText);
    realScreenText.current = newText;
    if (
      words.current[currentWord.current - 1]?.post.includes("?") &&
      jumpOption === "JUMP"
    ) {
      setStartWaiting(Math.random());
      isReading.current = false;
    } else {
      // reading next word
      if (phase === "READING" || phase === "REACTING")
        quizmasterTimer.current = setTimeout(readWord, nextTime);
    }
  };
  const [startReadingQuestion, setStartReadingQuestion] = useState(0);
  const quizmasterTimer = useRef<any>(null);
  useEffect(() => {
    if (startReadingQuestion) {
      const newId = setTimeout(readWord, 270);
      quizmasterTimer.current = newId;
    }

    return () => clearInterval(quizmasterTimer.current);
  }, [startReadingQuestion]);

  const isReading = useRef<boolean>(false);
  const [startWaiting, setStartWaiting] = useState(0);
  const waitingTimer = useRef<any>(undefined);
  useEffect(() => {
    if (startWaiting) {
      waitingTimer.current = setTimeout(() => {
        const newText = realScreenText.current + " No question.";
        setScreenText(newText);
        setPhase(READY);

        const newSet = [...questionSet];
        newSet.splice(0, 1);
        setQuestionSet(newSet);
      }, 3000);
    }

    return () => clearTimeout(waitingTimer.current);
  }, [startWaiting]);

  const callOnQuizzer = () => {
    const newText =
      realScreenText.current +
      `${
        !realScreenText.current.includes("?") ? " ...finish the question," : ""
      } ${quizzerNames[whoUp.current]}`;
    setScreenText(newText);
    realScreenText.current = newText;
    clearTimeout(quizmasterTimer.current);
    clearTimeout(waitingTimer.current);
    setPhase(ANSWERING);
    setStartTimekeeper(Math.random());
    quoteGiven.current = realScreenText.current.includes("Quote?");
    timeRemaining.current = quoteGiven.current ? 30 : 20;
    for (let i = 0; i < words.current.length; i++) {
      const word = words.current[i];
      if (word.post === ": " || word.text.includes("verse")) {
        wordsGiven.current =
          currentWord.current - (i + 2) + (currentSyllable.current > 0 ? 1 : 0);
        setGuessQuoteVisible(wordsGiven.current <= 0);
        break;
      }
    }
  };
  const [startReacting, setStartReacting] = useState(0);
  const reactionTimer = useRef<any>(undefined);
  useEffect(() => {
    if (startReacting) {
      const newId = setTimeout(callOnQuizzer, 200);
      reactionTimer.current = newId;
    }

    return () => clearTimeout(reactionTimer.current);
  }, [startReacting]);

  const [startTimekeeper, setStartTimekeeper] = useState(0);
  const timekeeperTimer = useRef<any>(undefined);
  const timeRemaining = useRef<number>(0);
  useEffect(() => {
    if (startTimekeeper) {
      const newId = setInterval(() => {
        timeRemaining.current = timeRemaining.current - 1;

        if (timeRemaining.current === 0) {
          clickShowAnswer(true);
        }
      }, 1000);
      timekeeperTimer.current = newId;
    }

    return () => clearInterval(timekeeperTimer.current);
  }, [startTimekeeper]);

  const whoUp = useRef(-1);
  const quoteGiven = useRef(false);
  const clickAskQuestion = () => {
    if (questionSet.length === 0) return;
    const fullQ = "Question, according to " + getFullQuestion(questionSet[0]);
    const newWords = breakupSyllables(fullQ);
    setFullQuestion(fullQ);

    // Take out () and [] from the question
    let isRemoving = false;
    for (let i = 0; i < newWords.length; i++) {
      const thisWord = newWords[i];
      if (thisWord.pre.includes("(") || thisWord.pre.includes("["))
        isRemoving = true;
      if (isRemoving) {
        newWords.splice(i, 1);
        i--;
      }
      if (thisWord.post.includes(")") || thisWord.post.includes("]")) {
        isRemoving = false;
        // Add ? to the last word
        if (!newWords[i].post.includes("?")) newWords[i].post = "?";
      }
    }

    // Start reading the next question
    words.current = newWords;
    setScreenText("");
    realScreenText.current = "";
    currentWord.current = 0;
    currentSyllable.current = 0;
    if (jumpOption === "JUMP") {
      // Jump like normal
      setPhase(READING);
      setStartReadingQuestion(Math.random());
      isReading.current = true;
    } else {
      // Read words until we're supposed to stop
      let remaining: number = 999;
      while (true) {
        readWord();
        const thisWord = words.current[currentWord.current];
        if (thisWord) {
          if (thisWord.post === ": " || thisWord.text.includes("verse")) {
            if (jumpOption === "REF") remaining = 3;
            if (jumpOption === "ONE") remaining = 4;
            if (jumpOption === "TWO") remaining = 5;
          }
          if (thisWord.post.includes("?")) remaining = Math.min(remaining, 2);
        }

        remaining--;
        if (remaining === 0) break;
      }

      // Call on the quizzer
      whoUp.current = 0;
      callOnQuizzer();
    }
  };
  const getShowAnswerStyle = (): CSSProperties => {
    if (whoUp.current < 0) return {};
    const currentJumpKey = jumpKeys[whoUp.current];
    if (!settings.touchscreen || typeof currentJumpKey === "string")
      return { marginTop: 20, marginRight: 10 };
    return {
      position: "fixed",
      left: currentJumpKey.x - 80,
      top: currentJumpKey.y - 20,
    };
  };
  const clickGuessQuote = () => {
    if (fullQuestion.includes("Quote?")) {
      quoteGiven.current = true;
      timeRemaining.current = timeRemaining.current + 10;
      setScreenText(`${screenText}\nQuote?`);
    } else {
      clickShowAnswer();
    }
    setGuessQuoteVisible(false);
  };
  const clickShowAnswer = (timeRanOut: boolean = false) => {
    setPhase(RULING);
    window.scrollTo(0, 0);
    setSwipeStatus(swipeStatusDefault);
    clearInterval(timekeeperTimer.current);
    setVerseViewing(questionSet[0].verse);
    setScreenText(
      `${screenText}${timeRanOut ? " Time!" : ""}\n\n${getFullQuestion(
        questionSet[0]
      )}`
    );
  };
  const makeRuling = (type: number) => {
    if (type === 0) {
      // 20 points
      const newCorrect = [...correct];
      newCorrect[whoUp.current] = newCorrect[whoUp.current] + 1;
      setCorrect(newCorrect);
    }
    if (type === 1) {
      // Error
      const newErrors = [...errors];
      newErrors[whoUp.current] = newErrors[whoUp.current] + 1;
      setErrors(newErrors);
    }
    if (type < 2) {
      // not throwout

      // Add words given
      const newWords = [...statsWords];
      newWords[whoUp.current] =
        newWords[whoUp.current] + Math.max(wordsGiven.current, 0);
      setStatsWords(newWords);

      // Time Used
      const newTime = [...timeUsed];
      newTime[whoUp.current] =
        newTime[whoUp.current] +
        (quoteGiven.current ? 30 : 20) -
        timeRemaining.current;
      setTimeUsed(newTime);

      // Questions Asked
      setQuestionsAsked(questionsAsked + 1);
    }

    // Get ready for new question
    const newSet = [...questionSet];
    newSet.splice(0, 1);
    setQuestionSet(newSet);
    setPhase(READY);
  };

  // Keyboard actions
  const quizzerJump = (index: number) => {
    whoUp.current = index;
    setPhase(REACTING);
    setStartReacting(Math.random());
  };
  const downHandler = (e: any) => {
    setPhase((currentPhase) => {
      if (
        (currentPhase === READING || currentPhase === WAITING) &&
        !settings.touchscreen
      ) {
        // Jump
        jumpKeys.forEach((key, index) => {
          if (key === e.code) {
            quizzerJump(index);
            return "REACTING";
          }
        });
      }
      if (currentPhase === ANSWERING) {
        // peek
        jumpKeys.forEach((key, index) => {
          if (key === e.code) {
            if (whoUp.current !== index) {
              // Peek
              setPeeking(true);
            }
          }
        });
      }
      return currentPhase;
    });
  };
  const upHandler = (e: any) => {
    if (phase === READY) {
      // Ask Question
      for (let i = 0; i < jumpKeys.length; i++) {
        if (jumpKeys[i] === e.code) {
          clickAskQuestion();
          return;
        }
      }
    }
    if (phase === JUMPKEYS && !settings.touchscreen) {
      setJumpKeys((original) => {
        // Select a jump key
        const keys = [...original];
        keys.push(e.code);
        return keys;
      });
      if (settings.touchscreen || jumpKeys.length + 1 === quizzerNames.length) {
        setPhase(READY);
      }
    }
    if (phase === ANSWERING) {
      // Stop peeking, of click show answer
      jumpKeys.forEach((key, index) => {
        if (key === e.code) {
          if (whoUp.current === index) {
            if (guessQuoteVisible) {
              clickGuessQuote();
            } else {
              clickShowAnswer();
            }
          } else {
            setPeeking(false);
          }
        }
      });
    }
    if (phase === RULING && jumpKeys[whoUp.current] === e.code) {
      // Person who got up getting 20 pointse
      makeRuling(0);
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  if (phase === JUMPKEYS) {
    return (
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          height: "calc(100vh - 20px)",
        }}
        data-testid="jump-key-container"
        onClick={(e) => {
          if (phase === JUMPKEYS && settings.touchscreen) {
            setJumpKeys((original) => {
              // Select a jump key
              const keys = [...original];
              keys.push({
                x: e.clientX,
                y: e.clientY,
              });

              return keys;
            });
            if (jumpKeys.length + 1 === quizzerNames.length) {
              setPhase(READY);
            }
          }
        }}
      >
        <div className="important-information">
          <p>
            {quizzerNames[jumpKeys.length] +
              `, please ${
                !settings.touchscreen
                  ? "select your jumping key"
                  : "tap on the screen where you want to jump"
              }.`}
          </p>
          <br />
          {!settings.touchscreen && (
            <p>
              If you do not have a keyboard, go back and select
              "Touchscreen-Friendly Interface" from the Settings menu.
            </p>
          )}
        </div>
      </div>
    );
  }

  return (
    <DoubleClickable
      style={{
        padding: 10,
        height: "calc(100vh - 20px)",
        touchAction: phase === RULING ? "none" : "auto",
      }}
      // Touchscreen functions
      doubleClick={() => {
        if (settings.touchscreen && phase === READY) {
          clickAskQuestion();
        }
      }}
      onTouchStart={(e) => {
        setSwipeStatus({
          ...swipeStatus,
          sX: e.touches[0].clientX,
          eX: e.touches[0].clientX,
          sY: e.touches[0].clientY,
          eY: e.touches[0].clientY,
        });
      }}
      onTouchMove={(e) => {
        setSwipeStatus({
          ...swipeStatus,
          eX: e.touches[0].clientX,
          eY: e.touches[0].clientY,
        });
      }}
      onTouchEnd={(e) => {
        if (settings.touchscreen && phase === RULING) {
          if (Math.abs(swipeStatus.eX - swipeStatus.sX) > swipeCutoff) {
            makeRuling(swipeStatus.eX > swipeStatus.sX ? 0 : 1);
          }
        }
        setSwipeStatus(swipeStatusDefault);
      }}
      data-testid="quiz-now-container"
    >
      {phase === RULING && settings.touchscreen && swipeStatus.sX >= 0 && (
        <div
          style={{
            backgroundColor: swipeStatus.sX < swipeStatus.eX ? "green" : "red",
            height: 5,
            position: "absolute",
            left: Math.min(swipeStatus.sX, swipeStatus.eX),
            top: swipeStatus.sY,
            width: Math.abs(swipeStatus.sX - swipeStatus.eX),
          }}
        />
      )}
      <div
        style={{ height: 200, display: "flex", whiteSpace: "pre-wrap" }}
      >{`${screenText.trim()}${
        qmPrecise &&
        (phase === "READING" || phase === "REACTING") &&
        currentSyllable.current > 0
          ? "-"
          : ""
      }`}</div>
      {phase === READY && (
        <>
          {questionSet.length > 0 ? (
            <>
              <div>
                {settings.touchscreen ? (
                  <p className="important-information">
                    Double-tap to ask the next question.
                  </p>
                ) : (
                  <button
                    className="clickable"
                    style={{ marginTop: 20, marginBottom: 20, marginRight: 10 }}
                    onClick={clickAskQuestion}
                  >
                    Ask Question
                  </button>
                )}
                <span>{`Total Questions: ${questionsAsked}`}</span>
              </div>
              {quizzerNames.length === 1 && (
                <Row>
                  <div style={{ marginRight: 10, marginTop: 2 }}>
                    Jump Setting:
                  </div>
                  {(Object.keys(JumpOptionDescriptions) as JumpOption[]).map(
                    (option: JumpOption) => (
                      <button
                        className="link"
                        style={{
                          backgroundColor:
                            jumpOption === option ? "#666666" : undefined,
                          padding: "0px 10px",
                          marginRight: 5,
                          height: 30,
                        }}
                        key={option}
                        onClick={() => setJumpOption(option)}
                      >
                        {JumpOptionDescriptions[option]}
                      </button>
                    )
                  )}
                </Row>
              )}
            </>
          ) : (
            <div>No more questions!</div>
          )}
        </>
      )}
      {phase === ANSWERING && (
        <button
          className="clickable"
          style={{ ...getShowAnswerStyle() }}
          onClick={() => {
            clickShowAnswer();
          }}
        >
          Show Answer
        </button>
      )}
      {phase === ANSWERING && guessQuoteVisible && (
        <button
          className="clickable"
          style={{ marginTop: 20 }}
          onClick={clickGuessQuote}
        >
          Guess Quote
        </button>
      )}
      {phase === RULING &&
        (settings.touchscreen ? (
          <div>
            <p className="important-information">
              Swipe right for 20 Points, left for Error.
            </p>
            <button
              className="link"
              onClick={() => makeRuling(2)}
              style={{ paddingLeft: 0 }}
            >
              Throwout
            </button>
          </div>
        ) : (
          <div>
            <Ruling onClick={(type: number) => makeRuling(type)} />
            <div style={{ marginTop: 20 }}>
              {`Time Remaining: ${timeRemaining.current}`}
            </div>
          </div>
        ))}
      {phase === READY && (
        <div>
          <div
            style={{
              minWidth: 200,
              maxWidth: "max(100%, 1000px)",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td style={{ width: statsColumnWidths[0] }}>Quizzer Name</td>
                  <td style={{ width: statsColumnWidths[1] }}>Correct</td>
                  <td style={{ width: statsColumnWidths[2] }}>Errors</td>
                  <td style={{ width: statsColumnWidths[3] }}>Words</td>
                  <td style={{ width: statsColumnWidths[4] }}>Time Used</td>
                </tr>
                {quizzerNames.map((quizzer: string, index: number) => (
                  <tr key={`quizzer-stats-${index}`}>
                    <td style={{ width: statsColumnWidths[0] }}>{quizzer}</td>
                    <td style={{ width: statsColumnWidths[1] }}>
                      {correct[index]}
                    </td>
                    <td style={{ width: statsColumnWidths[2] }}>
                      {errors[index]}
                    </td>
                    <td style={{ width: statsColumnWidths[3] }}>
                      {statsWords[index]}
                    </td>
                    <td style={{ width: statsColumnWidths[4] }}>
                      {timeUsed[index]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <button
            className="back-button"
            style={{ marginTop: 20 }}
            onClick={() => navigate("/")}
          >
            Go Back
          </button>
        </div>
      )}
      {phase === READING &&
        settings.touchscreen &&
        quizzerNames.map((quizzer: string, index: number) => {
          const jumpKey = jumpKeys[index];
          if (!jumpKey || typeof jumpKey === "string") return null;
          return (
            <button
              key={`quizzer-jump-${quizzer}`}
              className="clickable"
              style={{
                position: "fixed",
                left: jumpKey.x - 80,
                top: jumpKey.y - 20,
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                quizzerJump(index);
              }}
            >
              {quizzer}
            </button>
          );
        })}
      {phase === RULING && (
        <div
          style={{
            position: "absolute",
            bottom: 15,
            right: 15,
            width: "min(calc(100% - 30px), 600px)",
            maxHeight: "calc(100% - 350px)",
            height: 500,
          }}
        >
          <div
            style={{
              border: "1px solid grey",
              borderRadius: 5,
              overflowY: "scroll",
              height: "calc(100% - 40px)",
              padding: 4,
            }}
          >
            {getVerseAndQuestions(questionSet[0], verseViewing)}
          </div>
          <button
            className="clickable"
            style={{ width: 50, display: "inline-block" }}
            onClick={() => {
              if (verseViewing >= 1) setVerseViewing(verseViewing - 1);
            }}
          >
            {"<"}
          </button>
          <button
            className="clickable"
            style={{
              width: 50,
              display: "inline-block",
              position: "absolute",
              right: 0,
            }}
            onClick={() => {
              if (
                verseViewing + 1 <
                material[questionSet[0].book].chapters[questionSet[0].chapter]
                  .length
              )
                setVerseViewing(verseViewing + 1);
            }}
          >
            {">"}
          </button>
        </div>
      )}
      {phase === ANSWERING && peeking && (
        <label
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: 400,
            backgroundColor: "black",
            overflowY: "scroll",
          }}
        >
          {getFullQuestion(questionSet[0])}
          <br />
          <br />
          {getVerse(questionSet[0])}
        </label>
      )}
    </DoubleClickable>
  );
}
